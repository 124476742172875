import React, { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import { NotificationProvider } from 'context/NotificationContext/NotificationContext'
import { EventDBProvider } from 'context/EventDBContext/EventDBContext'
import routes from './routes/index'
import {
  StokrLoader,
  LoginWithOTPFlow,
  AuthProvider,
  AuthConsumer,
} from '@stokr/components-library'
import checkTodoStatus from 'utils/check-todo-status'
import GlobalStyle from 'styles/global'
import { ThemeProvider } from 'styled-components'
import theme from 'styles/theme'

const App = () => {
  const customValidateGetUser = (user) => {
    if (user.user_type === 'company_sa' || user.user_type === 'company_pa') {
      window.location.href = `https://admin.${process.env.REACT_APP_WEBSITE_DOMAIN}`
    }
  }

  return (
    <>
      <ThemeProvider theme={{ ...theme }}>
        <GlobalStyle />
        <AuthProvider customValidateGetUser={customValidateGetUser}>
          <AuthConsumer>
            {({ user, isFetchingUser, checkTokenIsValid }) => {
              return (
                <>
                  {user ? (
                    <EventDBProvider
                      user={user}
                      checkTokenIsValid={checkTokenIsValid}
                    >
                      <NotificationProvider
                        userId={user._id}
                        checkTokenIsValid={checkTokenIsValid}
                      >
                        <Routes>
                          {routes.map((route) => (
                            <Route
                              key={route.path}
                              path={route.path}
                              element={componentFactory(
                                route.element,
                                (route.isChecklist || checkTodoStatus(user)) &&
                                  !route.isOverview,
                              )}
                            >
                              {route.childRoutes &&
                                route.childRoutes.map((childRoute, index) => (
                                  <Route
                                    // index={index === 0}
                                    key={childRoute.path}
                                    path={childRoute.path}
                                    element={componentFactory(
                                      childRoute.element,
                                    )}
                                  />
                                ))}
                            </Route>
                          ))}
                        </Routes>
                      </NotificationProvider>
                    </EventDBProvider>
                  ) : isFetchingUser ? (
                    <StokrLoader />
                  ) : (
                    <LoginWithOTPFlow withBackground />
                  )}
                </>
              )
            }}
          </AuthConsumer>
        </AuthProvider>
      </ThemeProvider>
    </>
  )
}

const componentFactory = (Component, isChecklist, ...props) => {
  return <Component {...props} isChecklist={isChecklist} />
}

export default App
